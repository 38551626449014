.footer_div1 {
  color: white;
}
.footer_p {
  text-align: center;
}
.footer_p > a {
  color: #01adad;
  text-decoration: none;
}
.logo_footer {
display: flex;
justify-content: center;
padding-bottom: 8px;
}
