@media only screen and (max-width: 700px) {
  .navbar-toggler {
    border: none !important;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .dis {
    display: block !important; /* Hide collapsed navbar */
  }

  .navbar-nav .show {
    display: block; /* Show when not collapsed */
  }
}
