.sec2_main {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sec2_h1 {
  text-transform: uppercase;
  text-align: center;
}
.sec2_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  height:200px;
}
.sec2_h2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}
.sec2_pTag {
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .sec2_h1 {
    line-height: 1.4em;
    font-size: 24px;
    font-weight: 700;
  }
}
