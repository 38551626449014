.header_tagDiv {
  display: flex;
 
}
.header_bgcolor {
  background-color: #ecf9f9;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
}

.header_mainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aTag {
  color: #00b196;
  padding: 1em 0.5em;
  margin: 0 1em;
  text-decoration: none;
  cursor: pointer;
  position: relative; /* Required for the ::after pseudo-element positioning */
}

.aTag::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #00b196;
  transition: width 0.3s linear;
}

.aTag:hover::after {
  width: 100%;
}
.header_nav {
  background-color: white !important;
}
.active-link {
  color: #ffb100 !important; /* Active link color */
}
.phone_text {
  color: #00b196 !important;
}
.nv_m {
  font-size: 0.9375rem !important;
}
@media only screen and (max-width: 600px) {
  .logo_mobV {
    width: 109px;
  }
  .headerNav_con {
    padding: 1% 5%;
    padding-top: 1%;
    /* justify-content: ; */
  }
  .nav_borderB {
    border-bottom: 1px solid #ddd !important;
    padding: 0.8rem 0.5rem !important;
  }
  .borderTop {
    border-top: 1px solid #ddd !important;
  }
  .header_nav {
    padding-bottom: 0% !important;
  }
  .nv_m {
    margin-top: 1rem !important;
  }
  .bgYel {
    background-color: #f9c349;
    padding: 9px 15px;
  }
}
