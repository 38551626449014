.footer_maiDiv {
  padding: 7% 10% !important;
}
.footer_h1 {
  font-size: 1.875rem;
  line-height: 1.2;
  font-weight: 700;
}
.footer_divHead {
  margin-bottom: 4%;
}
.footer_h3 {
  font-size: 17px;
  font-weight: 700;
}
.div1_mar {
  margin-bottom: 2%;
}
.foo_p1 {
  line-height: 1.7;
}
.foo_main {
  background-color: #f8f7f3;
  padding: 4% 18% !important;
}
@media only screen and (max-width: 600px) {
  .foo_main {
    padding: 4% 2% !important;
  }
  .footer_maiDiv {
    padding: 8% 5% !important;
  }
}
