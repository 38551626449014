.reComp_img {
  max-width: 80%;
  height: auto;
}
.reComp_Col2Div {
  display: flex;
}
.reC_Col2h1 {
  text-transform: uppercase;
  line-height: 1.4em;
  font-size: 2.125rem;
  font-weight: 700;
}
.reC_Col2h1 > span {
  text-decoration: underline;
}
.divider {
  /* border-bottom: 3px solid #00a9a4; */
  max-width: 60px;
  height: 3px;
  background-color: #00a9a4;
}
.reCom_Col2Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.div_cen {
  display: flex;
  justify-content: center;
}
.reComp_ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reComp_li {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust spacing between list items as needed */
}

.reComp_li .fa-check {
  margin-right: 8px; /* Adjust this value to space the icon and text appropriately */
}

.reComp_text {
  display: inline-block;
  vertical-align: middle; /* Ensure the text aligns well with the icon */
}
.com1 {
  margin: 0rem 3rem !important;
}
@media only screen and (max-width: 600px) {
  .com1 {
    margin: 0% 1.5rem !important;
  }
  .reComp_Col2Div {
    justify-content: center;
  }
  .reC_Col2h1 {
    font-size: 24px;
  }
  .divider {
    margin: auto;
  }
  .divider_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    background-color: #00a9a4;
    max-width: 60px;
  }
}
