.term_h1 {
  font-weight: 700;
  font-size: 33px;
}
.term_pb {
  margin-bottom: 6%;
}
.term_h3 {
  font-size: 1.1rem;
  font-weight: 700;
  padding-bottom: 2.2%;
  padding-top: 2.2%;
}
.term_pb2 {
  padding-bottom: 2%;
}
.div_list {
  margin-left: 2%;
  margin-top: 4%;
}
.div_list li {
  padding-bottom: 0.5%;
}
.term_main {
  padding: 0% 5% !important;
}
