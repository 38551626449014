.sec1_main {
  background-color: #ecf9f9;
}
.sec1_col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 3rem;
}
.sec1_row {
  display: flex;
  justify-content: space-between;
}
.sec1_img {
  height: 95%;
  /* max-width: ; */
  width: 80%;
}
.sp_color {
  color: #00a9a4;
}
.sec1_col2 {
  width: 50%;
  display: flex;
  justify-content: center;
}
.sec1_btn {
  background-color: #00a9a4;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.9em 2em;
  font-size: 0.875rem;
}
.sec1_p1 {
  color: #00a9a4;
}
.sec1_h2 {
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 1.4em;
}
@media only screen and (max-width: 600px) {
  .sec1_h2 {
    font-size: 24px;
  }
  .sec1_col1 {
    padding: 0% 1.5rem !important;
  }
  .sec1_col2 {
    padding: 1.8rem 0rem;
  }
}
