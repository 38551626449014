.reComp_img2L {
  max-width: 90%;
  height: auto;
  -webkit-border-top-left-radius: 100px;
  -webkit-border-bottom-right-radius: 100px;
  -moz-border-radius-topleft: 100px;
  -moz-border-radius-bottomright: 100px;
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.reComp_img2R {
  max-width: 90%;
  height: auto;
  -webkit-border-top-right-radius: 100px;
  -webkit-border-bottom-left-radius: 100px;
  -moz-border-radius-topright: 100px;
  -moz-border-radius-bottomleft: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
}
.com2 {
  margin: 0% 3rem !important;
}
@media only screen and (max-width: 600px) {
  .com2 {
    margin: 0% 1.5rem !important;
  }
  .reCom_Col2Main {
    margin-bottom: 1rem;
  }
}
