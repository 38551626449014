.logo_div{
    height: 100px;
    width: 100%;
    max-width: 300px;
  
    box-sizing: border-box;
    padding: 10px 30px;
    margin-top: 10px;
}
.marquee_div_box{
    display: flex;
    gap: 2rem;
  

}